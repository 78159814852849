<template>
    <div id="nav" v-if="$store.state.user">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        <button @click="$store.dispatch('logout')">Logout</button>
    </div>
    <router-view/>
</template>

<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            store.dispatch('fetchUser')
        })
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
* {
    animation: fadein 1s ease 1;
    &::nth-child(1) {
        animation-duration: 1.5s;
    }
    &::nth-child(2) {
        animation-duration: 2s;
    }
    &::nth-child(3) {
        animation-duration: 2.5s;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
        filter: blur(10px);
    }
    100% {
        filter: blur(0px);
        opacity: 1;
    }
}

.rajdhani-light {
    font-family: "Rajdhani", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.rajdhani-regular {
    font-family: "Rajdhani", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.rajdhani-medium {
    font-family: "Rajdhani", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.rajdhani-semibold {
    font-family: "Rajdhani", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.rajdhani-bold {
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-style: normal;
}

body {
    font-family: "Rajdhani", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#app {
    position: fixed;
    display: flex;
    top: 0px;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
}

#nav {
    padding: 30px;
}

#nav a {}

#nav a.router-link-exact-active {}
</style>
