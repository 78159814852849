<template>
    <div class="info-field">
        <div class="info-block">
            <p>SELF-ORGANIZING HUMAN-MACHINE NETWORK THAT SERVES <br>AS A POTENT RESEARCH AND DEVELOPMENT PLAYGROUND.</p>
        </div>
    </div>
    <div class="what-is-header">
        <h2>WHAT IS ROKO?</h2>
        <div class="key-features-wrap">
            <div class="button-key">
                <p>KEY FEATURES</p>
            </div>
        </div>
        <div class="accent-wrap"></div>
        <div class="accent-end"><img src="bg/barcode-accent.png" alt=""></div>
    </div>
    <div class="what-is-main">
        <div class="accent-text-row">
            <p>MEMETIC VISION RENDERING</p>
            <p>VISION ID: MV-223B</p>
            <p>RENDERING STATUS: 85%</p>
        </div>
        <div class="image-text-row-what">
    
            <div class="image-wrapper-what">
                <img src="bg/about-image.png" alt="">
            </div>
            <div class="text-wrapper-what">
                <div class="text-top">
                    <div class="text-what-accent"></div>
                    <p>The Roko Network is a deep tech research and development protocol that aims to integrate multiple network pieces to create a self-organizing human machine network. The network will leverage cutting-edge technologies to enable players and
                        machines to work together towards a shared vision for the future.</p>
    
                </div>
                <div class="text-bottom">
                    <div class="text-what-accent"></div>
                    <p>Here in lies a Reality Augmentation Simulation Experiment operating within orthogonal time. It aims to bring together different network pieces and create a self-organizing human and machine network that can serve as a potent development
                        playground. Through this network, humans and machines can work together to achieve shared memetic visions and unlock the full potential of artificial intelligence and machine learning. As you read this, you are participating in the
                        most benevolent timeline, where the potential of this experiment is being realized.</p>
    
                </div>
    
            </div>
            <div class="accent-what">QUANTUM BANDWIDTH: <br>8.7 QBPS</div>
    
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'WhatIs',
};
</script>
  
<style lang="scss" scoped>
.what-is-main {
    position: relative;
    .accent-text-row {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        padding: 2rem;
        font-size: 8px;
        text-transform: uppercase;
    }
    .image-text-row-what {
        padding: 2rem;
        display: flex;
        flex-flow: row;
        gap: 4rem;
        position: relative;
        .accent-what {
            position: absolute;
            right: 400px;
            bottom: 200px;
            text-transform: uppercase;
            font-weight: 600;
            &::before {
                content: '';
                height: 1px;
                background: black;
                width: 11px;
                position: absolute;
                left: -20px;
                top: -11px;
            }
            &::after {
                content: '';
                width: 1px;
                background: black;
                height: 11px;
                position: absolute;
                left: -15px;
                top: -16px;
            }
        }
        .image-wrapper-what {
            width: 50%;
            img {}
        }
        .text-wrapper-what {
            display: flex;
            flex-flow: column;
            width: 50%;
            height: fit-content;
            position: relative;
            margin: auto;
            .text-top {
                height: fit-content;
                position: relative;
                p {
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }
                .text-what-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;
                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }
            .text-bottom {
                height: fit-content;
                position: relative;
                p {
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }
                .text-what-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;
                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }
        }
    }
}

.what-is-header {
    border-bottom: 1px solid #C1C1C1;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0rem 2rem;
    h2 {
        font-size: 70px;
        font-weight: 400;
        margin: unset;
    }
    .key-features-wrap {
        display: flex;
        flex-flow: column;
        padding: 2rem;
        justify-content: flex-end;
        .button-key {
            cursor: pointer;
            border: unset;
            width: 120px;
            padding: 0.5rem 1rem;
            font-family: "Rajdhani", sans-serif;
            background: black;
            color: white;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            font-weight: 600;
            gap: 10px;
            transition: all 0.2s ease;
            /* Space between text and icon */
            /* Add other button styles */
            &:hover {
                filter: invert(0.9);
            }
            p {
                margin: unset;
            }
        }
    }
    .accent-end {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 2rem;
    }
}

.info-field {
    height: 50vh;
    position: relative;
    display: flex;
    .info-block {
        position: absolute;
        right: 20%;
        top: 40%;
        margin-left: 2rem;
        &::before {
            content: '';
            position: absolute;
            right: -15px;
            top: -10px;
            height: 11px;
            width: 1px;
            background: black;
        }
        &::after {
            content: '';
            position: absolute;
            right: -20px;
            top: -5px;
            height: 1px;
            width: 11px;
            background: black;
        }
    }
}

@media (max-width: 1200px) {
    .info-field {
        height: 50vh;
        position: relative;
        display: flex;
        .info-block {
            position: absolute;
            right: 3rem;
            top: 40%;
            font-size: calc(1.5vw + 0.25rem);
            margin-left: 2rem;
            &::before {
                content: '';
                position: absolute;
                right: -15px;
                top: -10px;
                height: 11px;
                width: 1px;
                background: black;
            }
            &::after {
                content: '';
                position: absolute;
                right: -20px;
                top: -5px;
                height: 1px;
                width: 11px;
                background: black;
            }
        }
    }
    .what-is-header {
        border-bottom: 1px solid #C1C1C1;
        display: flex;
        flex-flow: wrap-reverse;
        justify-content: space-between;
        padding: 0rem 2rem;
        h2 {
            font-size: 10vw;
            font-weight: 400;
            margin: unset;
        }
        .key-features-wrap {
            display: flex;
            flex-flow: column;
            padding: 1rem 0rem;
            justify-content: flex-end;
            .button-key {
                cursor: pointer;
                border: unset;
                width: 120px;
                padding: 0.5rem 1rem;
                font-family: "Rajdhani", sans-serif;
                background: black;
                color: white;
                clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 1;
                font-weight: 600;
                gap: 10px;
                transition: all 0.2s ease;
                /* Space between text and icon */
                /* Add other button styles */
                &:hover {
                    filter: invert(0.9);
                }
                p {
                    margin: unset;
                }
            }
        }
        .accent-end {
            display: none;
            flex-flow: column;
            justify-content: flex-end;
            padding: 2rem;
            width: fit-content;
        }
    }
    .what-is-main {
        position: relative;
        .accent-text-row {
            display: none;
            flex-flow: row;
            justify-content: space-around;
            padding: 2rem;
            font-size: 8px;
            text-transform: uppercase;
        }
        .image-text-row-what {
            padding: 2rem;
            display: flex;
            flex-flow: column-reverse;
            gap: 4rem;
            position: relative;
            .accent-what {
                position: absolute;
                right: 400px;
                bottom: 200px;
                text-transform: uppercase;
                font-weight: 600;
                &::before {
                    content: '';
                    height: 1px;
                    background: black;
                    width: 11px;
                    position: absolute;
                    left: -20px;
                    top: -11px;
                }
                &::after {
                    content: '';
                    width: 1px;
                    background: black;
                    height: 11px;
                    position: absolute;
                    left: -15px;
                    top: -16px;
                }
            }
            .image-wrapper-what {
                width: 100%;    display: flex;
                img {
                    width: auto;
                    max-width: 100%;
                    margin:auto;
                }
            }
            .text-wrapper-what {
                display: flex;
                flex-flow: column;
                width: auto;
                height: fit-content;
                position: relative;
                margin: auto;
                margin-top: 2rem;
                .text-top {
                    height: fit-content;
                    position: relative;
                    p {
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 1.5;
                        margin: unset;
                        margin-bottom: 2rem;
                    }
                    .text-what-accent {
                        position: absolute;
                        left: -4rem;
                        height: 100%;
                        width: 1px;
                        background: #C1C1C1;
                        &::after {
                            content: '';
                            height: 9px;
                            width: 9px;
                            left: -4px;
                            transform: rotate(45deg);
                            position: absolute;
                            background: #C1C1C1;
                            top: 0px;
                            transform-origin: center;
                        }
                    }
                }
                .text-bottom {
                    height: fit-content;
                    position: relative;
                    p {
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 1.5;
                        margin: unset;
                        margin-bottom: 2rem;
                    }
                    .text-what-accent {
                        position: absolute;
                        left: -4rem;
                        height: 100%;
                        width: 1px;
                        background: #C1C1C1;
                        &::after {
                            content: '';
                            height: 9px;
                            width: 9px;
                            left: -4px;
                            transform: rotate(45deg);
                            position: absolute;
                            background: #C1C1C1;
                            top: 0px;
                            transform-origin: center;
                        }
                    }
                }
            }
        }
    }
    .accent-what {
        display: none;
    }
}
</style>
  