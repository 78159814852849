<template>
    <div class="prototypes-header">
        <h2>PROTOTYPES</h2>
    </div>
    <div class="prototype-cta-row">
        <div class="roko-ultra-wrapper">
            <img src="/bg/ultra.png" alt="Ultra Closed" @click="ultraswap = !ultraswap" v-if="!ultraswap">
            <img src="/bg/ultraopen.png" alt="Ultra Open" @click="ultraswap = !ultraswap" v-if="ultraswap">
        </div>
        <div class="roko-box-wrapper">
            <img src="/bg/rokobox.png" alt="Box Closed" @click="boxSwap = !boxSwap" v-if="!boxSwap">
            <img src="/bg/boxopen.png" alt="Box Open" @click="boxSwap = !boxSwap" v-if="boxSwap">
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatIs',
    data() {
        return {
            ultraswap: false,
            boxSwap: false
        };
    }
};
</script>

  
<style lang="scss" scoped>
.prototypes-header {
    border-bottom: 1px solid #C1C1C1;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0rem 2rem;
    h2 {
        font-size: 70px;
        font-weight: 400;
        margin: unset;
    }
    .key-features-wrap {
        display: flex;
        flex-flow: column;
        padding: 2rem;
        justify-content: flex-end;
        .button-key {
            cursor: pointer;
            border: unset;
            width: 120px;
            padding: 0.5rem 1rem;
            font-family: "Rajdhani", sans-serif;
            background: black;
            color: white;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            font-weight: 600;
            gap: 10px;
            transition: all 0.2s ease;
            /* Space between text and icon */
            /* Add other button styles */
            &:hover {
                filter: invert(0.9);
            }
            p {
                margin: unset;
            }
        }
    }
    .accent-end {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 2rem;
    }
}

.roko-ultra-wrapper {
    width: 100%;
    img {
        width: 100%;
    }
}

.roko-box-wrapper {
    width: 100%;
    img {
        width: 100%;
    }
}

.prototype-cta-row {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin: 2rem 0rem;
}

@media (max-width: 1200px) {
    .roko-ultra-wrapper {
        display: flex;
        img {
            width: auto;
            max-width: 100%;
            margin: auto;
        }
    }
    .roko-box-wrapper {
        display: flex;
        img {
            width: auto;
            max-width: 100%;
            margin: auto;
        }
    }
    .prototype-cta-row {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
    }
    .prototypes-header {
        border-bottom: 1px solid #C1C1C1;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 0rem 2rem;
        h2 {
            font-size: 10vw;
            font-weight: 400;
            margin: unset;
        }
    }
}
</style>
  