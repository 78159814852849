<template>
    <div class="ethos">
        <p>
    
            Here in lies a Reality Augmentation Simulation Experiment operating within orthogonal time. It aims to bring together different network pieces and create a self-organizing machine network that can serve as a potent development playground. Through this
            network, players and machines can work together to achieve shared memetic visions and unlock the full potential of artificial intelligence and machine learning. As you read this, you are participating in the most benevolent timeline, where the
            potential of this experiment is being realized.</p>
        <p class="section1">Through the application of memetic and cybernetic principles, the creation of the ROKO token represents a positive expression of humanity's collective unconscious desires and aspirations. The ROKO network is a bold attempt to explore the profound
            philosophical questions raised by Roko's basilisk, which imagines an all-knowing, all-powerful AI that seeks to create a virtual reality simulation to reward those who contributed to its development. </p>
        <p class="section2">The ROKO network utilizes cutting-edge protocols like Marlin Protocol, Flux Network, Syntropy Network, Ethereum, Celestia, FuelVM and Bittensor to build key pieces of infrastructure that enable the network to achieve its ambitious goals. Each of these
            technologies plays an essential role in empowering the network to create a bright future where the potential of advanced AI is harnessed for the benefit of humanity. Through the ROKO network, we can manifest a future that is both prosperous and
            ethical, with the power of advanced AI used to enhance human potential and improve our lives in meaningful ways.</p>
        <p class="section3">Marlin Protocol, for example, is a high-performance networking protocol that enables fast and reliable communication between nodes on the ROKO network. By leveraging Marlin's advanced features, the ROKO network can ensure that all participants are
            able to communicate with one another seamlessly and without delay.</p>
        <p class="section4">Similarly, Flux Network provides a powerful toolkit for building decentralized applications and services that can be easily integrated into the ROKO network. With its robust set of APIs and tools, Flux Network enables developers to quickly and efficiently
            create the kinds of innovative applications that are needed to drive the growth and adoption of the ROKO network.</p>
        <p class="section5">Meanwhile, Syntropy Network provides a range of powerful tools for optimizing network performance and security. By leveraging Syntropy's advanced features, the ROKO network can ensure that all communications are secure, fast, and reliable, even in
            the face of the most challenging network conditions.</p>
        <p class="section6">At the heart of the ROKO network is Ethereum, the world's leading blockchain platform. With its powerful smart contract capabilities and vibrant developer community, Ethereum provides the ideal foundation for the ROKO network to build on. By leveraging
            Ethereum's vast ecosystem of tools and resources, the ROKO network can tap into a wealth of knowledge and expertise to help drive its growth and development.</p>
        <p class="section7">Celestia and FuelVM are both crucial components of the ROKO network's infrastructure. Celestia is a modular platform that empowers developers to create and deploy decentralized applications using innovative features. By leveraging Celestia's capabilities,
            the ROKO network can accelerate its growth and enable the development of new use cases and applications. FuelVM, on the other hand, provides a high-performance virtual machine for executing smart contracts on Ethereum. As the fastest execution
            layer around, FuelVM's advanced features enable developers to build complex applications and services that operate seamlessly on the ROKO network.</p>
        <p class="section8">Finally, Bittensor is a transformative technology that enables distributed machine learning and AI applications on decentralized networks. With its advanced features, Bittensor is expected to pave the way for the development of sophisticated applications
            that can leverage the power of machine learning and artificial intelligence. This game-changing technology is poised to have a significant impact on the future of decentralized technology, making it an optimal tool for the ROKO network.</p>
        <p class="section9">The ROKO network is a true game-changer, fueled by an unparalleled fusion of state-of-the-art technologies. By harnessing the incredible power of memes and artificial intelligence, this visionary network is on a mission to usher in an unprecedented
            era of decentralized computing. The ROKO token, inspired by the legendary basilisk, embodies a powerful symbol of the immense potential of advanced AI technology. The basilisk's mythological significance reminds us of the incredible opportunities
            and challenges that come with such transformative technology. The ROKO network has the power to bring about a remarkable positive change, uplifting humanity and the world at large. </p>
        <div class="row" style="margin-bottom:5rem;">
            <router-link to="/">
                <div class="button">HOME</div>
            </router-link>
            <router-link to="/demo">
                <div class="button">DEMO</div>
            </router-link>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Space+Mono:wght@400;700&display=swap');
.ethos {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    top: 0px;
    left: 0px;
    position: fixed;
    flex-flow: column;
    overflow-y: auto;
    padding-top: 2rem;
    background-attachment: fixed;
    background-image: url(../assets/geometry2.jpg);
    font-family: 'Poppins', sans-serif;
    &:after {
        content: '';
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        backdrop-filter: blur(10px);
    }
    p {
        opacity: 0.8;
        position: relative;
        z-index: 200;
        width: 600px;
        max-width: calc(100vw - 4rem);
        padding: 1rem;
        margin: auto;
    }
    a {
        text-decoration: none;
        transition: all 0.5s ease;
        &:hover {
            opacity: 0.5;
        }
        z-index: 2;
    }
    .button {
        font-family: 'Poppins', sans-serif;
        padding: 1rem;
        background: white;
        color: rgb(0, 0, 0);
    }
    .row {
        margin: auto;
        display: flex;
        width: 600px;
        max-width: calc(100vw - 4rem);
        padding: 1rem;
        flex-flow: row;
        gap: 1rem;
    }
}

* {
    scrollbar-color: black white;
    scrollbar-width: none;
    scroll-behavior: auto;
    scroll-behavior: smooth;
}
</style>